import { PageProps } from '@lp-src/utils/local-types';
import { Col, FeaturedH2, Grid, Row } from '@web/atomic';
import SvgDoctorCouple from '@web/atomic/atm.svg-icon/img-doctor-couple.component';
import { hasWindow } from '@web/utils/platform';
import { getTopElementHeight, ScrollElement, scrollToElement } from '@web/utils/scroll';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import QueryString from 'querystringify';
import React, { useEffect } from 'react';
import { Blob1SectionStyled } from '../components/atm.background/background.styled';
import { SEO } from '../components/legacy/mol.seo/seo.component';
import { LandingPageHeroSectionLight } from '../components/mol.landing-page-hero-section-light/landing-page-hero-section-light.component';
import IndexLayout from '../components/org.layout/layout.component';
import { headerId } from '../components/org.menu/client-header.utils';
import HealthCarerPartnersRow from '../modules/health-carer/components/health-carer-partners-row.component';
import { HealthCarerReferralSection } from '../modules/health-carer/components/health-carers-referral.component';

interface HealthCarerPartnerQueryParams {
  // whether to scroll to "add your doctor" section
  add?: boolean;
}

const typeformSectionName = 'typeform-profissional-scroll';

const HealthCarerPartnerPage: React.FunctionComponent<PageProps> = (props) => {
  const onPrimaryClick = () => {
    scrollToElement(typeformSectionName, { top: getTopElementHeight(headerId) });
  };

  useEffect(() => {
    const params: HealthCarerPartnerQueryParams = QueryString.parse(hasWindow() ? window.location.search : '');
    if (params.add) {
      scrollToElement(typeformSectionName, { top: getTopElementHeight(headerId) });
    }
  }, []);

  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Indique um profissional da saúde para a Vigilantes do Sono',
          description: 'Conheça os atuais profissionais da saúde parceiros que melhoram o sono e indique mais nomes para esse time!',
        }}
      />
      <Grid>
        <LandingPageHeroSectionLight
          title={'Profissionais da saúde parceiros'}
          headline={'Seja acompanhado por um profissional da saúde parceiro'}
          primaryButton={{
            text: 'Indicar profissional da saúde',
            onClick: onPrimaryClick,
            id: 'ga-doctor-top-primary',
          }}
          illustration={<SvgDoctorCouple />}
        />
      </Grid>

      <Blob1SectionStyled>
        <Grid>
          {/* PROFESSIONAL SECTION */}
          <Row>
            <Col xs={12}>
              <FeaturedH2>Nossos parceiros</FeaturedH2>
            </Col>
          </Row>

          <HealthCarerPartnersRow />
        </Grid>
      </Blob1SectionStyled>

      {/* REFERRAL SECTION  */}
      <ScrollElement className="react-scroll-element" name={typeformSectionName} />
      <HealthCarerReferralSection />
    </IndexLayout>
  );
};

export default HealthCarerPartnerPage;

export const query = graphql`
  query HealthCarerPartnerPage {
    site {
      ...SiteUrl
    }
  }
`;
