import { Col, Row, Separator } from '@web/atomic';
import { LandingPageSubTitleStyled, LandingPageTitleStyled } from './landing-page-hero-section-light.styled';

import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { GatsbyButton } from '@components/atm.button/button.component';
import LargeSeparatorRow from '@lp-root/src/components/atm.large-separator-row/large-separator-row.component';
import * as React from 'react';

interface LandingPageHeroSectionProps {
  title: string;
  headline: string;
  primaryButton: {
    text: string;
    id: string;
    onClick?: () => void;
    to?: string;
    external?: boolean;
  };
  secondaryButton?: {
    text: string;
    id: string;
    to?: string;
    onClick?: () => void;
  };
  illustration: JSX.Element;
}

// eslint-disable-next-line react/display-name
export const LandingPageHeroSectionLight: React.FunctionComponent<LandingPageHeroSectionProps> = React.memo(
  (props) => {
    const hasSecondaryButton = !!props.secondaryButton;
    return (
      <section id={'inicio'}>
        <LargeSeparatorRow />
        <Row mt middle={'xs'}>
          <Col xs={12} sm={7} md={6}>
            <LandingPageTitleStyled>{props.title}</LandingPageTitleStyled>
            <LandingPageSubTitleStyled>{props.headline}</LandingPageSubTitleStyled>
            <ForcedFade show={true}>
              <Row>
                <Col xs={12} sm={hasSecondaryButton ? 6 : 12} md={hasSecondaryButton ? 6 : 12}>
                  <GatsbyButton
                    id={props.primaryButton.id}
                    to={props.primaryButton.to}
                    kind={'primary'}
                    onClick={props.primaryButton.onClick}
                    external={props.primaryButton.external}
                    expanded
                  >
                    {props.primaryButton.text}
                  </GatsbyButton>
                  <Separator />
                </Col>
                {hasSecondaryButton && (
                  <Col xs={12} sm={6} md={6}>
                    <GatsbyButton
                      id={props.secondaryButton.id}
                      onClick={props.secondaryButton.onClick}
                      to={props.secondaryButton.to}
                      kind={'secondary'}
                      expanded
                    >
                      {props.secondaryButton.text}
                    </GatsbyButton>
                    <Separator />
                  </Col>
                )}
              </Row>
            </ForcedFade>
          </Col>
          <Col xs={false} sm={5} mdOffset={1}>
            {props.illustration}
          </Col>
        </Row>
        <LargeSeparatorRow />
      </section>
    );
  },
  () => true
);
