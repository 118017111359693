import { FontSize, FontWeight, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const LandingPageTitleStyled = styled.p`
  font-size: ${FontSize.XLarge};
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${Spacing.Large};
`;

export const LandingPageSubTitleStyled = styled.p`
  font-size: ${FontSize.Medium};
  margin-bottom: ${Spacing.Large};
`;
