import { createEmailUrl } from '@global/utils/url/create-email-url';
import { createTelUrl } from '@global/utils/url/create-tel-url';
import { GatsbyButton } from '@lp-root/src/components/atm.button/button.component';
import { PersonCollapseCard } from '@lp-root/src/components/mol.person-collapse-card/person-collapse-card';
import { Body, FaIcon, LightDivisor, Separator } from '@web/atomic';
import * as React from 'react';

export interface HealthCarerCardProps {
  imgSrc: string;
  name: string;
  city: string;
  state: string;
  specialty?: string;
  phone?: string;
  email?: string;
  instagramLink?: string;
  linkedinLink?: string;
  siteLink?: string;
  acceptHealthInsurance?: boolean;
  professionId?: string;
}

export const HealthCarerCard: React.FunctionComponent<HealthCarerCardProps> = (props) => {
  const {
    imgSrc,
    name,
    specialty,
    city,
    state,
    phone,
    email,
    instagramLink,
    siteLink,
    linkedinLink,
    acceptHealthInsurance,
    professionId,
  } = props;

  const emailSubject = 'Quero fazer parte da Vigilantes do Sono';
  const emailBody = 'Olá, \n Vi seu nome na página da Vigilantes do Sono e gostaria de fazer parte.';

  const links: { name; path }[] = [
    siteLink && {
      name: (
        <>
          <FaIcon.Link size="lg" /> Site
        </>
      ),
      path: siteLink,
    },
    instagramLink && {
      name: (
        <>
          <FaIcon.Instagram size="lg" /> Instagram
        </>
      ),
      path: instagramLink,
    },
    linkedinLink && {
      name: (
        <>
          <FaIcon.Linkedin size="lg" /> LinkedIn
        </>
      ),
      path: linkedinLink,
    },
    phone && {
      name: (
        <>
          <FaIcon.Phone size="lg" /> {phone}
        </>
      ),
      path: createTelUrl(phone),
    },
    email && {
      name: (
        <>
          <FaIcon.Envelope size="lg" /> {email}
        </>
      ),
      path: createEmailUrl(email, emailSubject, emailBody),
    },
  ].filter((l) => l);

  return (
    <PersonCollapseCard
      title={name}
      description={specialty}
      descriptionSecondary={`${city} - ${state}`}
      imgSrc={imgSrc}
      imgAlt={`Foto de ${name}`}
    >
      <Separator />
      <LightDivisor />
      <Separator />
      {professionId && <Body center>{professionId}</Body>}
      {acceptHealthInsurance && (
        <Body center>
          {' '}
          <FaIcon.FileMedical /> Atende convênio
        </Body>
      )}
      <Separator />
      {links.map((l) => (
        <React.Fragment key={l.path}>
          <GatsbyButton kind="link" external to={l.path} expanded>
            {l.name}
          </GatsbyButton>
          <Separator />
        </React.Fragment>
      ))}
    </PersonCollapseCard>
  );
};
