import { Col, GrayColor, LightColor, Grid, Row } from '@web/atomic';
import React, { ReactElement, useEffect, useState } from 'react';

import { Blob2SectionStyled } from '@lp-root/src/components/atm.background/background.styled';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const typeformEmbed = require('@typeform/embed');

export const HealthCarerReferralSection: React.FunctionComponent = () => {
  return (
    <Blob2SectionStyled id={'envolva-se'}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <HealthCarerTypeform />
          </Col>
        </Row>
      </Grid>
    </Blob2SectionStyled>
  );
};

export const HealthCarerTypeform = (): ReactElement => {
  const className = 'typeform';
  const [error, setError] = useState();
  useEffect(() => {
    const loadTypeform = async () => {
      try {
        const url = 'https://taqtile.typeform.com/to/dobtCz5E';
        const embedElement = document.querySelector(`.${className}`); // NOTE: `.target-dom-node` is the target DOM element from your website or web app
        // https://developer.typeform.com/embed/modes/#widget-mode
        typeformEmbed.makeWidget(embedElement, url, { buttonText: 'Começar' });
      } catch (error) {
        console.error('ERROR: health-carers-referral.component.tsx ~ ', error);
        setError(error);
      }
    };

    loadTypeform();
  }, []);

  if (error) {
    return null;
  }
  return <div className={className} style={{ width: '100%', height: '500px', background: GrayColor.GrayXXLight }} />;
};
