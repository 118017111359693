import { Col, Row, Separator } from '@web/atomic';

import { HealthCarerCard } from './health-carer-card.component';
import * as React from 'react';
import { professionalsJSON } from '../health-carers/health-carers';

const HealthCarerPartnersRow: React.FunctionComponent = () => {
  const cards = professionalsJSON;

  return (
    <Row>
      {cards.map((item) => (
        <Col xs={12} sm={6} md={4} lg={4} key={item.name}>
          <HealthCarerCard {...item} />
          <Separator />
        </Col>
      ))}
    </Row>
  );
};

export default HealthCarerPartnersRow;
